<template>
  <div class="more-text">
    <div class="more-text__content" :class="{ 'more-text__content--full': isCollapsed }">
      <div ref="textBox" class="zamorano harakiri--text">
        {{ text }}
      </div>
    </div>

    <div class="more-text__button" v-if="isHeightEnough">
      <r-button-action :title="buttonText" color="rocky" size="briscola" @click.native="toggleText"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreText",

  props: {
    text: {
      type: String,
      default: () => '',
    },
  },

  mounted() {
    this.matchHeight();
  },

  watch: {
    text() {
      this.$nextTick(() => {
        this.isCollapsed = true;
        this.buttonText = this.$t('buttons.read_more');
        this.matchHeight();
      });
    }
  },

  data() {
    return {
      isCollapsed: true,
      isHeightEnough: true,
      buttonText: this.$t('buttons.read_more'),
    }
  },

  methods: {
    toggleText() {
      this.isCollapsed = !this.isCollapsed;
      this.buttonText = this.isCollapsed ? this.$t('buttons.collapse') : this.$t('buttons.read_more');
    },
    matchHeight() {
      let height = this.$refs.textBox.clientHeight;

      if (height > 160) {
        this.isHeightEnough = true;
        this.isCollapsed = false;
      } else {
        this.isHeightEnough = false;
        this.isCollapsed = true;
      }
    }
  }
}
</script>

<style lang="scss">
.more-text {

  &__content {
    height: 160px;
    overflow: hidden;

    &--full {
      height: auto;
    }
  }

  &__button {
    margin-top: 10px;
  }
}
</style>
