<template>
  <DetailedPageContainer>
    <div class="detailed__images" v-if="currentItem.images.length > 0">
      <r-row :wrap="false" class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 4 }"
               v-for="(img, index) in currentItem.images"
               :key="index">
          <r-image :url="img.replace('small', 'medium')"/>
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <h2 class="detailed__title--small taleggio harakiri--text">
          {{ currentItem.title }}
        </h2>

        <div class="mozzarella metropolis--text">
          {{ date }} · {{ city }}
        </div>
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <MoreText v-if="description" :text="description"/>
      </r-col>
    </r-row>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import MoreText from "@/components/DetailedViewParts/MoreText/MoreText";

export default {
  name: "DetailedNewsView",

  components: {
    DetailedPageContainer,
    MoreText,
  },

  computed: {
    ...mapGetters(['GET_NEWS',]),

    currentItem() {
      return this.GET_NEWS.filter(item => item.id == this.$route.params.id)[0];
    },

    city() {
      return this.currentItem?.city?.title;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    },

    date() {
      if (this.currentItem?.created_at) {
        return this.$moment(this.currentItem?.created_at * 1000).format('D MMMM');
      }

      return null;
    }
  },
}
</script>
